import { Component, OnInit } from '@angular/core';
import { productAccessName } from '../../shared/constant';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/shared/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  productAccessName: { onyx: string; topaz: string; sapphire: string; };
  isTopazAccessable: any = false;
  isOnyxAccessable: any = false;
  isSapphireAccessable: any = false;
  isLMSAccessable: any = false;
  getToken: any;
  currentProductAccess: string;
  getSapphireTenent: string;
  constructor(
    private router: Router,
    private tokenService:TokenService
  ) {
    this.productAccessName = productAccessName;
  }

  ngOnInit() {
    this.currentProductAccess = localStorage.getItem('products');
    let tempArrProduct = this.currentProductAccess.split(',');
    if (tempArrProduct) {
      tempArrProduct.forEach(e => {
        if (e == 'TOPAZ') {
          this.isTopazAccessable = true;
        }
        if (e == 'ONYX') {
          this.isOnyxAccessable = true;
        }
        if (e == 'SAPPHIRE') {
          this.isSapphireAccessable = true;
        }
        if (e == 'LMS') {
          this.isLMSAccessable = true;
        }
      });
    }
  }

  gotoProduct(product) {
    this.getToken = localStorage.getItem('logintoken') ? localStorage.getItem('logintoken') : null;
    this.getSapphireTenent = localStorage.getItem('sapphireTenent') ? localStorage.getItem('sapphireTenent') : null;
    if (this.getToken) {
      switch (product) {
        case 'topaz':
          window.open(`https://${environment.topazUrl}/#/tokenVerify?token=${this.getToken}`, '_blank');
          // window.open(`http://localhost:9000/#/tokenVerify?token=${this.getToken}`, '_blank');
          break;
        case 'onyx':
          window.open(`https://${environment.onyxUrl}/token-verify?token=${this.getToken}`, '_blank');
          // window.open(`http://localhost:4100/token-verify?token=${this.getToken}`, '_blank');
          break;
        case 'sapphire':
          window.open(`https://${this.getSapphireTenent}.sapphire-app.irisgst.com/#/tokenVerify?token=${this.getToken}&sapphireTenent=${this.getSapphireTenent}`, '_blank');
          // window.open(`http://localhost:9000/#/tokenVerify?token=${this.getToken}&sapphireTenent=${this.getSapphireTenent}`, '_blank');
          break;
          case 'lms':
            window.open(`https://${environment.lmsUrl}/token-verify?token=${this.getToken}`, '_blank');
            //window.open(`http://localhost:4100/token-verify?token=${this.getToken}`, '_blank');

            break;
      }
    } else {
      this.tokenService.destroy();
      this.router.navigate(['login']);
    }
  }


}
