import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../../../shared/services/toaster.service';
import { BusinessService } from '../../../shared/services/business.service';
import { BaseResponse } from '../../../models/response';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  subscriptionFlag: boolean = false;
  subcribeForm: FormGroup;
  verificationForm: FormGroup;
  isSubmitted: boolean = false;
  sapphire: boolean = false;
  onyx: boolean = false;
  topaz: boolean = false;
  onyxLite: boolean = false;
  sapphireTenantDoamin: any = 0;
  product: any = [];
  tenantList: any = [];
  key: string = environment.subscriptionKey;
  emailForm: FormGroup;
  constructor(
    private toaster: ToasterService,
    private fBuild: FormBuilder,
    private bs: BusinessService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.formInitialize();
    this.tenantList = [
      { key: 'asp', value: 'ASP' },
      { key: 'rama', value: 'RAMA' },
      { key: 'gt', value: 'GT' },
    ];
    this.verfiyPassword(false);
    console.log(this.key, 'KEYYYY');
    
  }
  formInitialize() {
    this.subcribeForm = this.fBuild.group({
      pan: [null, [Validators.required]]
    });
    this.verificationForm = this.fBuild.group({
      password: [null, [Validators.required]]
    });
    this.emailForm = this.fBuild.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }
  get ef() { return this.subcribeForm.controls; }
  get vf() { return this.verificationForm.controls; }
  get getEF() { return this.emailForm.controls; }

  verfiyPassword(flag) {
    this.subscriptionFlag = localStorage.getItem('subscriptionFlag') ? JSON.parse(localStorage.getItem('subscriptionFlag')) : null;
    if (!flag || this.subscriptionFlag) {
      return;
    }
    this.isSubmitted = true;
    if (flag && !this.verificationForm.valid) {
      return;
    }
    if (this.verificationForm.valid && flag) {
      if (this.key === this.verificationForm.value.password) {
        this.isSubmitted = false;
        this.subscriptionFlag = true;
        localStorage.setItem('subscriptionFlag', JSON.stringify(this.subscriptionFlag));
      }
      else {
        this.toaster.showWarning('Please enter the valid key');
        this.isSubmitted = false;
      }
    } else {
      this.isSubmitted = false;
    }
  }

  navigateToDashboard() {
    this.router.navigate([`dashboard`]);
  }

  clearData() {
    this.subcribeForm.reset();
    this.verificationForm.reset();
    this.emailForm.reset();
    this.sapphire = false;
    this.onyx = false;
    this.onyxLite = false;
    this.topaz = false;
  }

  search() {
    this.isSubmitted = true;
    if (this.product.length == 0) {
      this.toaster.showWarning('At least one product required');
      return;
    }
    if (this.sapphire && (this.sapphireTenantDoamin == 0 || this.sapphireTenantDoamin == null)) {
      this.toaster.showWarning('Tenant is required');
      return;
    }

    if(this.onyxLite && !this.emailForm.valid){
      return;
    }

    if (this.subcribeForm.valid) {
      let model = {
        pan: this.subcribeForm.value.pan,
        products: this.product,
        sapphireTenant: this.sapphire ? this.sapphireTenantDoamin : null,
        emailId: this.onyxLite? this.emailForm.value.email: null,
        isOnyxLite: this.onyxLite
      }
      this.bs.subscribeData(model).subscribe((response: BaseResponse) => {
        if (response.response) {
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message)
        }
      }, (err) => {
        this.toaster.showError("Network Error");
      });

    }
  }

  setProduct() {
    this.product = [];
    if (this.sapphire) {
      this.product.push('SAPPHIRE');
    } else {
      this.remove(this.product, 'SAPPHIRE');
    }
    if (this.onyx) {
      this.product.push('ONYX');
    } else {
      this.remove(this.product, 'ONYX');
    }
    if (this.onyxLite) {
      if (!this.onyx) {
        this.onyxLite = false;
      }
    }
    if (this.topaz) {
      this.product.push('TOPAZ');
    } else {
      this.remove(this.product, 'TOPAZ');
    }

  }

  remove(arrOriginal, elementToRemove) {
    return arrOriginal.filter(function (el) { return el !== elementToRemove });
  }


}
