import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { BaseResponse } from '../../../models/response';
import { ToasterService } from '../../../shared/services/toaster.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  
  signupForm: FormGroup;
  isSubmitted: boolean;
  isDisabled: boolean = false;
  constructor(
    private fBuild: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService
  ) {
  }

  ngOnInit() {
    this.formInitialization();
  }

  formInitialization() {
    this.signupForm = this.fBuild.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,16}$'))]]
    });
  }
  get f() { return this.signupForm.controls; }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  login() {
    this.router.navigate(['login']);
  }

  signup() {
    this.isSubmitted = true;
    this.isDisabled = true;
    if (this.signupForm.valid) {
      this.authService.signup(this.signupForm.value).subscribe((response: BaseResponse) => {
        this.isDisabled = false;
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess('Registration Successful!');
          this.router.navigate(['thankyou']);
        } else {
          this.toaster.showError("Regsistration Failed..! " + response.message);
        }
      });
    }
  }


}
