import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, ReplaySubject, from, BehaviorSubject, Subject, throwError } from 'rxjs';
import { TokenService } from 'src/app/shared/services/token.service';
import { catchError, map, retry} from 'rxjs/operators';
import { BaseResponse } from './../../models/response';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  initialLoginValue = this.isAuthenticated() ? 2 : this.isLoginAuthenticated() ? 1 : 0;
  loginIndicator = new BehaviorSubject(this.initialLoginValue); // 0: Not logged in , 1: Init Login Done, 2: Fully Logged In
  checkChangePass$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private toasterService: ToasterService
  ) { }

  getLoginIndicator(): Observable<any> {
    return this.loginIndicator.asObservable();
  }
  getLoginEmail(): Observable<any> {
    return this.checkChangePass$.asObservable();
  }

  setLoginIndicator(data: number): boolean {
    // console.log('Logging Indicator new Value: ' + data);
    this.loginIndicator.next(data);
    return true;
  }
  setLoginEmail(data: any) {
   return this.checkChangePass$.next(data);
  }

  login(body) {
    return this.http.post(`${environment.apiUrl}/mgmt/login`, body).pipe(map((response) => {
      return response
    }));
  }

  resetPassword(data) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/forgotpassword`, data).pipe(map((response) => {
      return response
    }));
  }

  doResetPassword(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/resetpassword`, data).pipe(map((response) => {
      return response
    }));
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('jwtToken');
    return !this.tokenService.isTokenExpired(token);
  }

  isLoginAuthenticated(): boolean {
    const token = localStorage.getItem('logintoken');
    return !this.tokenService.isTokenExpired(token);

  }
  signup(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/registration`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  resetPass(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/resetpassword`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  confirmRegistration(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/confirmregistration`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  changePassowrd(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/public/user/changepassword`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  tokenVerify(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/public/user/checktokenvalidity?`, {
      params: {
        token: data
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  comapnyList() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/entity`).pipe(map((resp) => {
      return resp;
    }));
  }

  getSAMLLoginLink(domainName: string): Observable<BaseResponse> {
    return this.http
      .get(`${environment.apiUrl}/mgmt/login/sso?domain=${domainName}`)
      .pipe(
        map((response) => {
          return <BaseResponse>response;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  validateToken(token: string): Observable<BaseResponse> {
    return this.http
      .get(`${environment.apiUrl}/mgmt/public/token/validate?token=${token}`)
      .pipe(
        map((response) => {
          return <BaseResponse>response;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  handleError = (error: any) => {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    this.toasterService.showError(
      "There was an error while retrieving data. Please refresh the page."
    );
    return throwError(() => {
      return errorMessage;
    });
  };

}
