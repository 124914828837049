<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="text-center">
                                <h3 class="clrWhite">MULTI-FACTOR AUTHENTICATION</h3>
                            </div>
                            <div class="auth-form-transparent  text-left py-5 px-4 px-sm-5 border">
                                <div class="brand-logo">
                                    <img src="assets/images/IRISGST-logo.png" alt="logo">
                                </div>
                                <form [formGroup]="mfaForm" class="pt-3">
                                    <h4 class="clrWhite">Enter MFA Code to complete Sign In</h4>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="text" class="form-control" formControlName="code"
                                                placeholder="MFA Code">
                                        </div>
                                        <div *ngIf="isSubmitted">
                                            <span class="text-danger" *ngIf="mfaForm.get('code').errors">Please
                                                enter
                                                valid code
                                            </span>
                                        </div>
                                    </div>
                                    <div class="my-3">
                                        <button type="submit" class="btn btn-primary ml-1"
                                            class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            (click)="verifyOtp()">VERIFY</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
