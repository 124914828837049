import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../../shared/services/token.service';
import { BaseResponse } from '../../../models/response';
import { Router } from '@angular/router';
import { ToasterService } from '../../../shared/services/toaster.service';
import { GetterSetterService } from '../../../shared/services/getter-setter.service';
import { BusinessHeaderComponent } from '../../../base/components/business-header/business-header.component';
import { productAccessName } from '../../../shared/constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  productAccessName: { onyx: string; topaz: string; sapphire: string; };
  @ViewChild(BusinessHeaderComponent) childBusinessHeaderComp: BusinessHeaderComponent;
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  subArrowShow: boolean;
  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private toaster: ToasterService,
    private getSet: GetterSetterService,
  ) {
    this.productAccessName = productAccessName;
  }

  ngOnInit() {
    this.formInitialization();
  }

  formInitialization() {
    this.loginForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      client: ['UI', []]
    });
  }

  login() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.loginForm.value.client = 'UI';
      this.authService.login(this.loginForm.value).subscribe((response: BaseResponse) => {
        if (response.status === 'SUCCESS') {
          if (response.response.mfa_enable) {
            this.getSet.getSetUserId(this.loginForm.value.email);
            this.authService.setLoginIndicator(0);
            this.getSet.setMFACode(response.response.mfa_enable);
            this.router.navigate([`verify-otp`]);
          } else {
            this.toaster.showSuccess('Login Successful.');
            this.tokenService.setter(response.response);
            this.authService.setLoginIndicator(2);
            this.getSet.getSetUser(response.response.username);
            this.getSet.getSetSapphireTenent(response.response.sapphireTenant);
            this.getSet.getSetUserId(response.response.email);
            if (response.response.rootCompanyid != undefined) {
              this.getSet.getSetCompanyId(response.response.rootCompanyid);
              this.getSet.getSetCompany(response.response.rootCompanyName);
              if (response.response.products.length > 0) {
                this.getSet.setProduct(response.response.products);
              }
              this.router.navigate([`dashboard`]);
            } else {
              this.router.navigate([`add-business`]);
            }
          }
        } else {
          if (response.response != null && response.response.isValidPassword == false) {
            this.toaster.showError('Your password has expired and must be changed after redirecting.');
            this.authService.setLoginEmail(response.response.email);
            this.router.navigate(['change-password']);
          }
          this.toaster.showError(response.message);
        }
      });
    }
  }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  signup() {
    this.router.navigate(['signup']);
  }
}
