import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../../shared/services/toaster.service';
import { BaseResponse } from '../../../models/response';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  tokenParam: any;

  constructor(
    private fBuild: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute
  ) { 
    this.tokenParam = this.activeSnapShot.snapshot.queryParams.token;
  }

  ngOnInit() {
    this.formInitialization();
  }

  formInitialization() {
    this.resetForm = this.fBuild.group({
      newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      token: [this.tokenParam, [Validators.required]]
    });
  }

  resetPassword() {
    if (this.resetForm.valid) {
      this.authService.resetPass(this.resetForm.value).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess(response.message);
          this.router.navigate(['login']);
        } else {
          this.toaster.showError(response.message);
        }
      });
    }
  }

}
