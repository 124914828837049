<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper nopadding">
            <div class="dashboard-header d-flex flex-column grid-margin">

                <div class="d-md-flex align-items-center justify-content-between flex-wrap">




                </div>
            </div>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src="/assets/images/dashbg.png" alt="First slide">
                        <div class="carousel-caption">
                            <h2>IRIS GST Product Suite </h2>
                            <h4>Everything you need for simple and hassle-free GST compliance and more</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class=" jumbotron clearfix my-lg-4">
                <h1 class="text-center">Get Started</h1>
                <div class="row no-gutters p-md-5 p-2">

                    <div class="col-md-4 col-12 px-2" *ngIf="isOnyxAccessable">
                        <div class="grid-margin stretch-card">
                            <div class="card shadow">
                                <div class="card-body text-center">
                                    <img class="d-block text-center mauto h90" src="/assets/images/barcodes.png"
                                        alt="First slide">
                                    <p class="card-title text-center fs22"><a (click)="gotoProduct('onyx');"
                                            class="clrOnyx">Onyx</a></p>
                                    <div>
                                        <p class="clrGray fs16">Generate, print and manage all your e-invoicing
                                            operations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 px-2" *ngIf="isTopazAccessable">
                        <div class="grid-margin stretch-card">
                            <div class="card shadow">
                                <div class="card-body text-center">
                                    <img class="d-block text-center mauto h90" src="/assets/images/trucks.png"
                                        alt="First slide">
                                    <p class="card-title text-center  fs22"><a (click)="gotoProduct('topaz');"
                                            class="clrTopaz">Topaz</a></p>
                                    <div>
                                        <p class="clrGray fs16">Generate, Update Part B and easily perform all
                                            operations related to e-way bill</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 px-2" *ngIf="isSapphireAccessable">
                        <div class="grid-margin stretch-card">
                            <div class="card shadow">
                                <div class="card-body text-center">
                                    <img class="d-block text-center mauto h90" src="/assets/images/interface.png"
                                        alt="First slide">
                                    <p class="card-title text-center fs22"><a (click)="gotoProduct('sapphire');"
                                            class="clrSapphire">Sapphire</a></p>
                                    <div>
                                        <p class="clrGray fs16"> Prepare your GST returns and reconcile your purchase
                                            data</p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 px-2" *ngIf="isLMSAccessable">
                        <div class="grid-margin stretch-card">
                            <div class="card shadow">
                                <div class="card-body text-center">
                                    <img class="d-block text-center mauto h90" src="/assets/images/lms3.png"
                                        alt="First slide">
                                    <p class="card-title text-center fs22"><a (click)="gotoProduct('lms');"
                                            class="clrLMS">LMS</a></p>
                                    <div>
                                        <p class="clrGray fs16">Litigation Management System</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
    <!-- main-panel ends -->
</div>