<div class="horizontal-menu">
  <nav class="navbar top-navbar col-lg-12 col-12 p-0">
    <div class="container">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a class="navbar-brand brand-logo" (click)="navigateDashboard()"><img src="assets/images/IRISGST-logo.png" alt="logo"
          /></a>
        <a class="navbar-brand brand-logo-mini" (click)="navigateDashboard()"><img src="assets/images/IRISGST-logo.png" alt="logo"
          /></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">   
        <div id="menu1">
          <ul class="navbar-nav navbar-nav-left ml-4 ">
            <li class="nav-item dropdown"><a (click)="GotoUserDash()"><i class="fa fa-building"></i></a></li>
            <li class="nav-item dropdown ">
              <a class="nav-link count-indicator dropdown-toggle d-flex  align-items-center" id="navbarDropdown" data-toggle="dropdown">
                <span class="companyName"><i class="fa fa-caret-right"></i></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="navbarDropdown">
                <div class="autoScroll">
                  <div *ngFor=" let business of BNBusinessList">
                     <a class="dropdown-item text-overflow" (click)="changeBusiness(business.companyid)">
                      <i class="fa fa-tree"></i> {{business.company}}</a> 
                      <!-- <div class="dropdown-divider"></div> -->
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown shwHighRes" id="{{BNModel?.companyId}}">
              <a class="nav-link count-indicator dropdown-toggle d-flex  align-items-center" data-toggle="dropdown">
                <i class="fa fa-building"></i> <span class="companyName">{{BNModel?.companyName}}</span>
              </a>
            </li>
            <!-- <li class="nav-item dropdown">{{rootBusinessName}}</li> -->
          </ul>
        </div>     
        <ul class="navbar-nav navbar-nav-right shwHighRes">
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link shwHighRes" data-toggle="dropdown" id="profileDropdown">
              {{currentUser}} <i class="fa fa-user ml-2"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown mt-3 mr-2" aria-labelledby="profileDropdown">
              <a class="dropdown-item" (click)="goToChangePassword('changePass')">
                <i class="fa fa-user-o"></i>
                My Account
              </a>
              
              <a class="dropdown-item" (click)="goToAddBusissness()">
                <i class="fa fa-plus-square"></i>
                Add Business
              </a>
              <a class="dropdown-item" (click)="logout()">
                <i class="fa fa-sign-out"></i>
                Logout
              </a>
            </div>
          </li>
          <li class="nav-item dropdown" id="notfiyDiv">
             <!-- <a  class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center notifyAnchr" id="notificationDropdown" data-toggle="dropdown">
              <i class="mdi mdi-bell-outline mx-0 notifyIcon"></i>
              <span class="count"></span>
            </a>  -->
             
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
          <span class="mdi mdi-menu"></span>
        </button>
      </div>
    </div>
  </nav>
  
</div>
