import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../shared/services/toaster.service';
import { Router } from '@angular/router';
import { GetterSetterService } from '../shared/services/getter-setter.service';
import { BaseResponse } from '../models/response';
import { BusinessService } from '../shared/services/business.service';
import { productAccessName } from '../shared/constant';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss']
})
export class AddBusinessComponent implements OnInit {
  productAccessName: { onyx: string; topaz: string; sapphire: string; };
  addBusinessForm: FormGroup;
  subscribeForm: FormGroup;
  isSubmitted: boolean = false;
  activeStep1: boolean = true;
  activeStep2: boolean = false;
  activeStep3: boolean = false;
  businessDetailsFilled: any;
  subscriptionCode:any;
  entityType: string;
  isTopazAccess: any;
  isSapphireAccess: any;
  isOnyxAccess: any;
  isDisabled: boolean = false;
  constructor(
    private toaster: ToasterService,
    private router: Router,
    private getterSetter: GetterSetterService,
    private fBuild: FormBuilder,
    private businessSerivce: BusinessService
  ) {
    this.productAccessName = productAccessName;
   }

  ngOnInit(): void {
    this.formInitialization();
  }

  OpenForm(step: string) {
    if (step == 'step2' && this.businessDetailsFilled) {
      this.activeStep1 = false;
      this.activeStep2 = true;
      this.activeStep3 = false;
    }
    else {
      this.activeStep1 = true;
      this.activeStep2 = false;
      this.activeStep3 = false;
    }
  }
  formInitialization() {
    this.addBusinessForm = this.fBuild.group({
      companyname: ['', [Validators.required]],
      pan: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(new RegExp('^[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}'))]],
      address: []
    });
    this.subscribeForm = this.fBuild.group({
      subCode: ['', [Validators.required]]
    });
  }
  checkSubmitValidation(data: any) {
    this.isSubmitted = true;
    this.isDisabled = true;
    if (this.addBusinessForm.valid && data == 'addBusinessTab') {
      this.businessSerivce.getSubscriptionPlan(this.addBusinessForm.value).subscribe((response: BaseResponse) => {
        this.isDisabled = false;
        if (response.status == 'SUCCESS') {
          this.businessDetailsFilled = true;
          // this.activeStep1 = false;
          // this.activeStep2 = true;
          this.subscriptionCode = response.response.subscriptionCode;
          this.isTopazAccess = response.response.topazAccess;
          this.isSapphireAccess = response.response.sapphireAccess;
          this.isOnyxAccess = response.response.onyxAcess;
          this.addBusiness();
        } else if (response.status == 'FAILURE') {
          this.toaster.showWarning(response.message);
        }
      });
    } else {
      this.isDisabled = false;
    }
    // if (this.subscribeForm.valid && data == 'subscribeTab') {
    //     if (this.subscribeForm.value.subCode == this.subscriptionCode) {
    //       this.entityType = "BUSINESS";
    //       this.activeStep1 = false;
    //       this.activeStep2 = true;
    //       this.checkValidationsForFinish(this.subscriptionCode);
    //     } else {
    //       this.toaster.showWarning("Please Enter Valid Subscription Code");
    //     }
    // }
  }

  checkValidationsForFinish(subCodeVal: any) {
    if (this.addBusinessForm.valid) {
      if (subCodeVal != undefined) {
            this.entityType = "BUSINESS";
            this.addBusiness();
      } else {
        this.toaster.showError('Enter valid subscription code.');
      }
    } else {
      this.toaster.showWarning('Please fill Business Details');
    }
  }


  addBusiness() {
    let model = {
      'companyname': this.addBusinessForm.value.companyname,
      'pan': this.addBusinessForm.value.pan,
      'address': this.addBusinessForm.value.address,
      'entitytype': 'BUSINESS'
    };

    this.businessSerivce.addBusinessFunction(model).subscribe((response: BaseResponse) => {
      if (response.status == 'SUCCESS') {
        this.toaster.showSuccess(response.message);
        this.getterSetter.getSetCompany(this.addBusinessForm.value.companyname);
        this.getterSetter.getSetCompanyId(response.response.companyid);
        this.getterSetter.getSetProductAccess(this.productAccessName.topaz,this.isTopazAccess);
        this.getterSetter.getSetProductAccess(this.productAccessName.sapphire,this.isSapphireAccess);
        this.getterSetter.getSetProductAccess(this.productAccessName.onyx,this.isOnyxAccess);
        this.router.navigate([`dashboard`]);
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

}
