export const APP_NAME = {
    ONYX: 'onyx',
    TOPAZ: 'topaz',
    Saphhire: 'saphhire'
};

export const productAccessName = {
    onyx: 'isOnyxAccess',
    topaz: 'isTopazAccess',
    sapphire: 'isSapphireAccess',
}

export const UserRoleMap = {
    12: { role: 'Maker', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'D_all_err_warn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'GSTR3Upload', 'ChooseGSTR3Files'] },
    8: { role: 'Preparer'/*checker*/, access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    9: { role: 'Signatory', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    1: { role: 'Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    11: { role: 'Guest', access: [] },
    0: { role: 'NoRole', access: [] },
    3: { role: 'Generator', access: ['NewInvoice', 'DownloadSampleTemplate'] },
    4: { role: 'Reviewer', access: ['NewInvoice', 'DownloadSampleTemplate'] },
}

export const paginationPrams = {
    pageLen: 10,
    pageLen50: 50,
    pageOption: [10, 50, 100, 200]
}

export const taxpayerTypes = [
    { key: 'NORMAL', displayName: 'Normal' },
    { key: 'SEZ', displayName: 'SEZ' },
    { key: 'ISD', displayName: 'Input Service Distributor' },
    { key: 'ECOM', displayName: 'E-commerce Operator' },
    { key: 'TDS', displayName: 'TDS Deductor' },
    { key: 'NRTP', displayName: 'Non-resident taxable Person' },
    { key: 'OIDT', displayName: 'Person supplying online information and database access' },
    { key: 'UIN', displayName: 'UIN-holders' },
    { key: 'CD', displayName: 'Taxpayer opting for composition scheme' },
    { key: 'TRANSPORTER', displayName: 'Transporter' }
]

export const DOCUMENT_TYPE = [
    { label: 'Regular Invoice', value: 'RI' },
    { label: 'Credit Note', value: 'C' },
    { label: 'Debit Note', value: 'D' },
    { label: 'Bill of Supply', value: 'BS' },
    { label: 'Bill of Entry', value: 'BOE' },
    { label: 'Delivery Challan', value: 'DC' },
    { label: 'Others', value: 'OTH' }
]

export const DOCUMENT_STATUS = [
    { label: 'Original', value: 'O' },
    { label: 'Rverse', value: 'R' },
]

export const INVOICE_TYPE = [
    { value: 'B2B', label: 'Regular B2B Invoices of registered counterparty' },
    { value: 'SEWOP', label: 'SEZ Exports without payment' },
    { value: 'SEWP', label: 'SEZ Exports with payment' },
    { value: 'DE', label: 'Deemed Export' },
    { value: 'EXWP', label: 'Export with payment of duty' },
    { value: 'EXWOP', label: 'Export without payment of duty' },
    // { value: 'DEWP', label: 'Deemed Exports with Payment' },
    // { value: 'DEWOP', label: 'Deemed Exports without Payment' },
    // { value: 'B2G', label: 'Business to Government' },
    { value: 'B2C', label: 'Regular B2C Invoices of unregistered counterparty' },
    // { value: 'SEDWP', label: 'SEZ DEVELOPER with payment' },
    // { value: 'SEDWOP', label: 'SEZ DEVELOPER without payment' }
]

export const SUPPLY_TYPE = [
    { key: 'O', value: 'Outward' },
    { key: 'I', value: 'Inward' }
]

export const REVERSE_CHARGE = [
    { label: 'NO', value: 'N' },
    { label: 'YES', value: 'Y' },
]

export const INVOICE_STATUS = [
    { label: 'TO BE SAVED', value: 'UPLOADED' },
    { label: 'SAVED', value: 'IRN_GENERATED' },
]
export const TRANSACTION_TYPE = [
    { label: 'Regular', value: 'REG' },
    { label: 'Bill To - Ship To', value: 'SHP' },
    { label: 'Bill From - Dispatch From', value: 'DIS' },
    { label: 'Bill To - Ship To and Bill From - Dispatch From', value: 'CMB' },
]

export const IRN_STATUS = [
    { label: 'ACTIVE', value: 'ACT' },
    { label: 'CANCEL', value: 'CNL' },
]

export const ERROR_WARNING_LIST = [
    { label: 'Onyx Error', value: 'hasError' },
    { label: 'Irn Error', value: 'hasIrnError' },
    { label: 'Warning', value: 'hasWarning' },
    { label: 'No Error', value: 'noError' },
]

export const OPERATOR_LIST = [
    { value: "eq", key: "Equal to" },
    // { value: "in", key: "is one of" },
    { value: "lt", key: "Less Than" },
    { value: "gt", key: "Greater Than" },
    // { value: "lte", key: "<=" },
    // { value: "gte", key: ">=" }
]

export const CANCEL_REASON = [
    { name: "Duplicate", val: 1 },
    { name: "Data Entry Mistake", val: 2 }
]

export const FILTER_LIST = [

    { key: "AckDt", value: "Ack Date" },
    { key: "bgstin", value: "Buyer gstin" },
    { key: "btrdNm", value: "Buyer Trade Name" },
    { key: "blglNm", value: "Buyer Legal Name" },
    { key: "cnlDt", value: "Cancelled Date" },
    { key: "docDt", value: "Document Date" },
    { key: "no", value: "Document Number" },
    { key: "docType", value: "Document Type" },
    { key: "totinvval", value: "Document Value" },
    { key: "errWarn", value: "Error/warning" },
    { key: "itemCount", value: "Item Count" },
    { key: "irnStatus", value: "IRN Status" },
    { key: "catg", value: "Invoice Type" },
    { key: "supplyType", value: "Supply Type" },
    // { key: "invStatus", value: "Invoice Status" },
    { key: "UpDt", value: "Uploaded Date" }

]

export const STATE_LIST = [
    {
        "stateName": "Andhra Pradesh",
        "stateCode": "37",
        "id": 1
    },
    {
        "stateName": "Arunachal Pradesh",
        "stateCode": "12",
        "id": 2
    },
    {
        "stateName": "Assam",
        "stateCode": "18",
        "id": 3
    },
    {
        "stateName": "Bihar",
        "stateCode": "10",
        "id": 4
    },
    {
        "stateName": "Chhattisgarh",
        "stateCode": "22",
        "id": 5
    },
    {
        "stateName": "Goa",
        "stateCode": "30",
        "id": 6
    },
    {
        "stateName": "Gujarat",
        "stateCode": "24",
        "id": 7
    },
    {
        "stateName": "Haryana",
        "stateCode": "06",
        "id": 8
    },
    {
        "stateName": "Himachal Pradesh",
        "stateCode": "02",
        "id": 9
    },
    {
        "stateName": "Jammu and Kashmir",
        "stateCode": "01",
        "id": 10
    },
    {
        "stateName": "Jharkhand",
        "stateCode": "20",
        "id": 11
    },
    {
        "stateName": "Karnataka",
        "stateCode": "29",
        "id": 12
    },
    {
        "stateName": "Kerala",
        "stateCode": "32",
        "id": 13
    },
    {
        "stateName": "Madhya Pradesh",
        "stateCode": "23",
        "id": 14
    },
    {
        "stateName": "Maharashtra",
        "stateCode": "27",
        "id": 15
    },
    {
        "stateName": "Manipur",
        "stateCode": "14",
        "id": 16
    },
    {
        "stateName": "Meghalaya",
        "stateCode": "17",
        "id": 17
    },
    {
        "stateName": "Mizoram",
        "stateCode": "15",
        "id": 18
    },
    {
        "stateName": "Nagaland",
        "stateCode": "13",
        "id": 19
    },
    {
        "stateName": "Orissa",
        "stateCode": "21",
        "id": 20
    },
    {
        "stateName": "Punjab",
        "stateCode": "03",
        "id": 21
    },
    {
        "stateName": "Rajasthan",
        "stateCode": "08",
        "id": 22
    },
    {
        "stateName": "Sikkim",
        "stateCode": "11",
        "id": 23
    },
    {
        "stateName": "Tamil Nadu",
        "stateCode": "33",
        "id": 24
    },
    {
        "stateName": "Telangana",
        "stateCode": "36",
        "id": 25
    },
    {
        "stateName": "Tripura",
        "stateCode": "16",
        "id": 26
    },
    {
        "stateName": "Uttarakhand",
        "stateCode": "05",
        "id": 27
    },
    {
        "stateName": "Uttar Pradesh",
        "stateCode": "09",
        "id": 28
    },
    {
        "stateName": "West Bengal",
        "stateCode": "19",
        "id": 29
    },
    {
        "stateName": "Andaman and Nicobar Islands",
        "stateCode": "35",
        "id": 30
    },
    {
        "stateName": "Chandigarh",
        "stateCode": "04",
        "id": 31
    },
    {
        "stateName": "Dadra and Nagar Haveli",
        "stateCode": "26",
        "id": 32
    },
    {
        "stateName": "Daman and Diu",
        "stateCode": "25",
        "id": 33
    },
    {
        "stateName": "National Capital Territory of Delhi union territory",
        "stateCode": "07",
        "id": 34
    },
    {
        "stateName": "Lakshadweep",
        "stateCode": "31",
        "id": 35
    },
    {
        "stateName": "Puducherry",
        "stateCode": "34",
        "id": 36
    },
    {
        "stateName": "Other Territory",
        "stateCode": "97",
        "id": 1020
    },
    {
        "stateName": "Other Country",
        "stateCode": "96",
        "id": 1021
    }
]
export const COUNTRY_LIST = [
    {
        "countryName": "Andorra",
        "countryCode": "AD",
        "id": 1
    },
    {
        "countryName": "United Arab Emirates",
        "countryCode": "AE",
        "id": 2
    },
    {
        "countryName": "Afghanistan",
        "countryCode": "AF",
        "id": 3
    },
    {
        "countryName": "Antigua and Barbuda",
        "countryCode": "AG",
        "id": 4
    },
    {
        "countryName": "Anguilla",
        "countryCode": "AI",
        "id": 5
    },
    {
        "countryName": "Albania",
        "countryCode": "AL",
        "id": 6
    },
    {
        "countryName": "Armenia",
        "countryCode": "AM",
        "id": 7
    },
    {
        "countryName": "Angola",
        "countryCode": "AO",
        "id": 8
    },
    {
        "countryName": "Antarctica",
        "countryCode": "AQ",
        "id": 9
    },
    {
        "countryName": "Argentina",
        "countryCode": "AR",
        "id": 10
    },
    {
        "countryName": "American Samoa",
        "countryCode": "AS",
        "id": 11
    },
    {
        "countryName": "Austria",
        "countryCode": "AT",
        "id": 12
    },
    {
        "countryName": "Australia",
        "countryCode": "AU",
        "id": 13
    },
    {
        "countryName": "Aruba",
        "countryCode": "AW",
        "id": 14
    },
    {
        "countryName": "Åland Islands",
        "countryCode": "AX",
        "id": 15
    },
    {
        "countryName": "Azerbaijan",
        "countryCode": "AZ",
        "id": 16
    },
    {
        "countryName": "Bosnia and Herzegovina",
        "countryCode": "BA",
        "id": 17
    },
    {
        "countryName": "Barbados",
        "countryCode": "BB",
        "id": 18
    },
    {
        "countryName": "Bangladesh",
        "countryCode": "BD",
        "id": 19
    },
    {
        "countryName": "Belgium",
        "countryCode": "BE",
        "id": 20
    },
    {
        "countryName": "Burkina Faso",
        "countryCode": "BF",
        "id": 21
    },
    {
        "countryName": "Bulgaria",
        "countryCode": "BG",
        "id": 22
    },
    {
        "countryName": "Bahrain",
        "countryCode": "BH",
        "id": 23
    },
    {
        "countryName": "Burundi",
        "countryCode": "BI",
        "id": 24
    },
    {
        "countryName": "Benin",
        "countryCode": "BJ",
        "id": 25
    },
    {
        "countryName": "Saint Barthelemy",
        "countryCode": "BL",
        "id": 26
    },
    {
        "countryName": "Bermuda",
        "countryCode": "BM",
        "id": 27
    },
    {
        "countryName": "Brunei Darussalam",
        "countryCode": "BN",
        "id": 28
    },
    {
        "countryName": "Bolivia (Plurinational State of)",
        "countryCode": "BO",
        "id": 29
    },
    {
        "countryName": "Bonaire, Sint Eustatius and Saba",
        "countryCode": "BQ",
        "id": 30
    },
    {
        "countryName": "Brazil",
        "countryCode": "BR",
        "id": 31
    },
    {
        "countryName": "Bahamas",
        "countryCode": "BS",
        "id": 32
    },
    {
        "countryName": "Bhutan",
        "countryCode": "BT",
        "id": 33
    },
    {
        "countryName": "Bouvet Island",
        "countryCode": "BV",
        "id": 34
    },
    {
        "countryName": "Botswana",
        "countryCode": "BW",
        "id": 35
    },
    {
        "countryName": "Belarus",
        "countryCode": "BY",
        "id": 36
    },
    {
        "countryName": "Belize",
        "countryCode": "BZ",
        "id": 37
    },
    {
        "countryName": "Canada",
        "countryCode": "CA",
        "id": 38
    },
    {
        "countryName": "Cocos (Keeling) Islands",
        "countryCode": "CC",
        "id": 39
    },
    {
        "countryName": "Congo, Democratic Republic of the",
        "countryCode": "CD",
        "id": 40
    },
    {
        "countryName": "Central African Republic",
        "countryCode": "CF",
        "id": 41
    },
    {
        "countryName": "Congo",
        "countryCode": "CG",
        "id": 42
    },
    {
        "countryName": "Switzerland",
        "countryCode": "CH",
        "id": 43
    },
    {
        "countryName": "Cote dIvoire",
        "countryCode": "CI",
        "id": 44
    },
    {
        "countryName": "Cook Islands",
        "countryCode": "CK",
        "id": 45
    },
    {
        "countryName": "Chile",
        "countryCode": "CL",
        "id": 46
    },
    {
        "countryName": "Cameroon",
        "countryCode": "CM",
        "id": 47
    },
    {
        "countryName": "China",
        "countryCode": "CN",
        "id": 48
    },
    {
        "countryName": "Colombia",
        "countryCode": "CO",
        "id": 49
    },
    {
        "countryName": "Costa Rica",
        "countryCode": "CR",
        "id": 50
    },
    {
        "countryName": "Cuba",
        "countryCode": "CU",
        "id": 51
    },
    {
        "countryName": "Cabo Verde",
        "countryCode": "CV",
        "id": 52
    },
    {
        "countryName": "Curacao",
        "countryCode": "CW",
        "id": 53
    },
    {
        "countryName": "Christmas Island",
        "countryCode": "CX",
        "id": 54
    },
    {
        "countryName": "Cyprus",
        "countryCode": "CY",
        "id": 55
    },
    {
        "countryName": "Czechia",
        "countryCode": "CZ",
        "id": 56
    },
    {
        "countryName": "Germany",
        "countryCode": "DE",
        "id": 57
    },
    {
        "countryName": "Djibouti",
        "countryCode": "DJ",
        "id": 58
    },
    {
        "countryName": "Denmark",
        "countryCode": "DK",
        "id": 59
    },
    {
        "countryName": "Dominica",
        "countryCode": "DM",
        "id": 60
    },
    {
        "countryName": "Dominican Republic",
        "countryCode": "DO",
        "id": 61
    },
    {
        "countryName": "Algeria",
        "countryCode": "DZ",
        "id": 62
    },
    {
        "countryName": "Ecuador",
        "countryCode": "EC",
        "id": 63
    },
    {
        "countryName": "Estonia",
        "countryCode": "EE",
        "id": 64
    },
    {
        "countryName": "Egypt",
        "countryCode": "EG",
        "id": 65
    },
    {
        "countryName": "Western Sahara",
        "countryCode": "EH",
        "id": 66
    },
    {
        "countryName": "Eritrea",
        "countryCode": "ER",
        "id": 67
    },
    {
        "countryName": "Spain",
        "countryCode": "ES",
        "id": 68
    },
    {
        "countryName": "Ethiopia",
        "countryCode": "ET",
        "id": 69
    },
    {
        "countryName": "Finland",
        "countryCode": "FI",
        "id": 70
    },
    {
        "countryName": "Fiji",
        "countryCode": "FJ",
        "id": 71
    },
    {
        "countryName": "Falkland Islands (Malvinas)",
        "countryCode": "FK",
        "id": 72
    },
    {
        "countryName": "Micronesia (Federated States of)",
        "countryCode": "FM",
        "id": 73
    },
    {
        "countryName": "Faroe Islands",
        "countryCode": "FO",
        "id": 74
    },
    {
        "countryName": "France",
        "countryCode": "FR",
        "id": 75
    },
    {
        "countryName": "Gabon",
        "countryCode": "GA",
        "id": 76
    },
    {
        "countryName": "United Kingdom of Great Britain and Northern Ireland",
        "countryCode": "GB",
        "id": 77
    },
    {
        "countryName": "Grenada",
        "countryCode": "GD",
        "id": 78
    },
    {
        "countryName": "Georgia",
        "countryCode": "GE",
        "id": 79
    },
    {
        "countryName": "French Guiana",
        "countryCode": "GF",
        "id": 80
    },
    {
        "countryName": "Guernsey",
        "countryCode": "GG",
        "id": 81
    },
    {
        "countryName": "Ghana",
        "countryCode": "GH",
        "id": 82
    },
    {
        "countryName": "Gibraltar",
        "countryCode": "GI",
        "id": 83
    },
    {
        "countryName": "Greenland",
        "countryCode": "GL",
        "id": 84
    },
    {
        "countryName": "Gambia",
        "countryCode": "GM",
        "id": 85
    },
    {
        "countryName": "Guinea",
        "countryCode": "GN",
        "id": 86
    },
    {
        "countryName": "Guadeloupe",
        "countryCode": "GP",
        "id": 87
    },
    {
        "countryName": "Equatorial Guinea",
        "countryCode": "GQ",
        "id": 88
    },
    {
        "countryName": "Greece",
        "countryCode": "GR",
        "id": 89
    },
    {
        "countryName": "South Georgia and the South Sandwich Islands",
        "countryCode": "GS",
        "id": 90
    },
    {
        "countryName": "Guatemala",
        "countryCode": "GT",
        "id": 91
    },
    {
        "countryName": "Guam",
        "countryCode": "GU",
        "id": 92
    },
    {
        "countryName": "Guinea-Bissau",
        "countryCode": "GW",
        "id": 93
    },
    {
        "countryName": "Guyana",
        "countryCode": "GY",
        "id": 94
    },
    {
        "countryName": "Hong Kong",
        "countryCode": "HK",
        "id": 95
    },
    {
        "countryName": "Heard Island and McDonald Islands",
        "countryCode": "HM",
        "id": 96
    },
    {
        "countryName": "Honduras",
        "countryCode": "HN",
        "id": 97
    },
    {
        "countryName": "Croatia",
        "countryCode": "HR",
        "id": 98
    },
    {
        "countryName": "Haiti",
        "countryCode": "HT",
        "id": 99
    },
    {
        "countryName": "Hungary",
        "countryCode": "HU",
        "id": 100
    },
    {
        "countryName": "Indonesia",
        "countryCode": "ID",
        "id": 101
    },
    {
        "countryName": "Ireland",
        "countryCode": "IE",
        "id": 102
    },
    {
        "countryName": "Israel",
        "countryCode": "IL",
        "id": 103
    },
    {
        "countryName": "Isle of Man",
        "countryCode": "IM",
        "id": 104
    },
    {
        "countryName": "India",
        "countryCode": "IN",
        "id": 105
    },
    {
        "countryName": "British Indian Ocean Territory",
        "countryCode": "IO",
        "id": 106
    },
    {
        "countryName": "Iraq",
        "countryCode": "IQ",
        "id": 107
    },
    {
        "countryName": "Iran (Islamic Republic of)",
        "countryCode": "IR",
        "id": 108
    },
    {
        "countryName": "Iceland",
        "countryCode": "IS",
        "id": 109
    },
    {
        "countryName": "Italy",
        "countryCode": "IT",
        "id": 110
    },
    {
        "countryName": "Jersey",
        "countryCode": "JE",
        "id": 111
    },
    {
        "countryName": "Jamaica",
        "countryCode": "JM",
        "id": 112
    },
    {
        "countryName": "Jordan",
        "countryCode": "JO",
        "id": 113
    },
    {
        "countryName": "Japan",
        "countryCode": "JP",
        "id": 114
    },
    {
        "countryName": "Kenya",
        "countryCode": "KE",
        "id": 115
    },
    {
        "countryName": "Kyrgyzstan",
        "countryCode": "KG",
        "id": 116
    },
    {
        "countryName": "Cambodia",
        "countryCode": "KH",
        "id": 117
    },
    {
        "countryName": "Kiribati",
        "countryCode": "KI",
        "id": 118
    },
    {
        "countryName": "Comoros",
        "countryCode": "KM",
        "id": 119
    },
    {
        "countryName": "Saint Kitts and Nevis",
        "countryCode": "KN",
        "id": 120
    },
    {
        "countryName": "Korea (Democratic Peoples Republic of)",
        "countryCode": "KP",
        "id": 121
    },
    {
        "countryName": "Korea, Republic of",
        "countryCode": "KR",
        "id": 122
    },
    {
        "countryName": "Kuwait",
        "countryCode": "KW",
        "id": 123
    },
    {
        "countryName": "Cayman Islands",
        "countryCode": "KY",
        "id": 124
    },
    {
        "countryName": "Kazakhstan",
        "countryCode": "KZ",
        "id": 125
    },
    {
        "countryName": "Lao Peoples Democratic Republic",
        "countryCode": "LA",
        "id": 126
    },
    {
        "countryName": "Lebanon",
        "countryCode": "LB",
        "id": 127
    },
    {
        "countryName": "Saint Lucia",
        "countryCode": "LC",
        "id": 128
    },
    {
        "countryName": "Liechtenstein",
        "countryCode": "LI",
        "id": 129
    },
    {
        "countryName": "Sri Lanka",
        "countryCode": "LK",
        "id": 130
    },
    {
        "countryName": "Liberia",
        "countryCode": "LR",
        "id": 131
    },
    {
        "countryName": "Lesotho",
        "countryCode": "LS",
        "id": 132
    },
    {
        "countryName": "Lithuania",
        "countryCode": "LT",
        "id": 133
    },
    {
        "countryName": "Luxembourg",
        "countryCode": "LU",
        "id": 134
    },
    {
        "countryName": "Latvia",
        "countryCode": "LV",
        "id": 135
    },
    {
        "countryName": "Libya",
        "countryCode": "LY",
        "id": 136
    },
    {
        "countryName": "Morocco",
        "countryCode": "MA",
        "id": 137
    },
    {
        "countryName": "Monaco",
        "countryCode": "MC",
        "id": 138
    },
    {
        "countryName": "Moldova, Republic of",
        "countryCode": "MD",
        "id": 139
    },
    {
        "countryName": "Montenegro",
        "countryCode": "ME",
        "id": 140
    },
    {
        "countryName": "Saint Martin (French part)",
        "countryCode": "MF",
        "id": 141
    },
    {
        "countryName": "Madagascar",
        "countryCode": "MG",
        "id": 142
    },
    {
        "countryName": "Marshall Islands",
        "countryCode": "MH",
        "id": 143
    },
    {
        "countryName": "North Macedonia",
        "countryCode": "MK",
        "id": 144
    },
    {
        "countryName": "Mali",
        "countryCode": "ML",
        "id": 145
    },
    {
        "countryName": "Myanmar",
        "countryCode": "MM",
        "id": 146
    },
    {
        "countryName": "Mongolia",
        "countryCode": "MN",
        "id": 147
    },
    {
        "countryName": "Macao",
        "countryCode": "MO",
        "id": 148
    },
    {
        "countryName": "Northern Mariana Islands",
        "countryCode": "MP",
        "id": 149
    },
    {
        "countryName": "Martinique",
        "countryCode": "MQ",
        "id": 150
    },
    {
        "countryName": "Mauritania",
        "countryCode": "MR",
        "id": 151
    },
    {
        "countryName": "Montserrat",
        "countryCode": "MS",
        "id": 152
    },
    {
        "countryName": "Malta",
        "countryCode": "MT",
        "id": 153
    },
    {
        "countryName": "Mauritius",
        "countryCode": "MU",
        "id": 154
    },
    {
        "countryName": "Maldives",
        "countryCode": "MV",
        "id": 155
    },
    {
        "countryName": "Malawi",
        "countryCode": "MW",
        "id": 156
    },
    {
        "countryName": "Mexico",
        "countryCode": "MX",
        "id": 157
    },
    {
        "countryName": "Malaysia",
        "countryCode": "MY",
        "id": 158
    },
    {
        "countryName": "Mozambique",
        "countryCode": "MZ",
        "id": 159
    },
    {
        "countryName": "Namibia",
        "countryCode": "NA",
        "id": 160
    },
    {
        "countryName": "New Caledonia",
        "countryCode": "NC",
        "id": 161
    },
    {
        "countryName": "Niger",
        "countryCode": "NE",
        "id": 162
    },
    {
        "countryName": "Norfolk Island",
        "countryCode": "NF",
        "id": 163
    },
    {
        "countryName": "Nigeria",
        "countryCode": "NG",
        "id": 164
    },
    {
        "countryName": "Nicaragua",
        "countryCode": "NI",
        "id": 165
    },
    {
        "countryName": "Netherlands",
        "countryCode": "NL",
        "id": 166
    },
    {
        "countryName": "Norway",
        "countryCode": "NO",
        "id": 167
    },
    {
        "countryName": "Nepal",
        "countryCode": "NP",
        "id": 168
    },
    {
        "countryName": "Nauru",
        "countryCode": "NR",
        "id": 169
    },
    {
        "countryName": "Niue",
        "countryCode": "NU",
        "id": 170
    },
    {
        "countryName": "New Zealand",
        "countryCode": "NZ",
        "id": 171
    },
    {
        "countryName": "Oman",
        "countryCode": "OM",
        "id": 172
    },
    {
        "countryName": "Panama",
        "countryCode": "PA",
        "id": 173
    },
    {
        "countryName": "Peru",
        "countryCode": "PE",
        "id": 174
    },
    {
        "countryName": "French Polynesia",
        "countryCode": "PF",
        "id": 175
    },
    {
        "countryName": "Papua New Guinea",
        "countryCode": "PG",
        "id": 176
    },
    {
        "countryName": "Philippines",
        "countryCode": "PH",
        "id": 177
    },
    {
        "countryName": "Pakistan",
        "countryCode": "PK",
        "id": 178
    },
    {
        "countryName": "Poland",
        "countryCode": "PL",
        "id": 179
    },
    {
        "countryName": "Saint Pierre and Miquelon",
        "countryCode": "PM",
        "id": 180
    },
    {
        "countryName": "Pitcairn",
        "countryCode": "PN",
        "id": 181
    },
    {
        "countryName": "Puerto Rico",
        "countryCode": "PR",
        "id": 182
    },
    {
        "countryName": "Palestine, State of",
        "countryCode": "PS",
        "id": 183
    },
    {
        "countryName": "Portugal",
        "countryCode": "PT",
        "id": 184
    },
    {
        "countryName": "Palau",
        "countryCode": "PW",
        "id": 185
    },
    {
        "countryName": "Paraguay",
        "countryCode": "PY",
        "id": 186
    },
    {
        "countryName": "Qatar",
        "countryCode": "QA",
        "id": 187
    },
    {
        "countryName": "Reunion",
        "countryCode": "RE",
        "id": 188
    },
    {
        "countryName": "Romania",
        "countryCode": "RO",
        "id": 189
    },
    {
        "countryName": "Serbia",
        "countryCode": "RS",
        "id": 190
    },
    {
        "countryName": "Russian Federation",
        "countryCode": "RU",
        "id": 191
    },
    {
        "countryName": "Rwanda",
        "countryCode": "RW",
        "id": 192
    },
    {
        "countryName": "Saudi Arabia",
        "countryCode": "SA",
        "id": 193
    },
    {
        "countryName": "Solomon Islands",
        "countryCode": "SB",
        "id": 194
    },
    {
        "countryName": "Seychelles",
        "countryCode": "SC",
        "id": 195
    },
    {
        "countryName": "Sudan",
        "countryCode": "SD",
        "id": 196
    },
    {
        "countryName": "Sweden",
        "countryCode": "SE",
        "id": 197
    },
    {
        "countryName": "Singapore",
        "countryCode": "SG",
        "id": 198
    },
    {
        "countryName": "Saint Helena, Ascension and Tristan da Cunha",
        "countryCode": "SH",
        "id": 199
    },
    {
        "countryName": "Slovenia",
        "countryCode": "SI",
        "id": 200
    },
    {
        "countryName": "Svalbard and Jan Mayen",
        "countryCode": "SJ",
        "id": 201
    },
    {
        "countryName": "Slovakia",
        "countryCode": "SK",
        "id": 202
    },
    {
        "countryName": "Sierra Leone",
        "countryCode": "SL",
        "id": 203
    },
    {
        "countryName": "San Marino",
        "countryCode": "SM",
        "id": 204
    },
    {
        "countryName": "Senegal",
        "countryCode": "SN",
        "id": 205
    },
    {
        "countryName": "Somalia",
        "countryCode": "SO",
        "id": 206
    },
    {
        "countryName": "Suriname",
        "countryCode": "SR",
        "id": 207
    },
    {
        "countryName": "South Sudan",
        "countryCode": "SS",
        "id": 208
    },
    {
        "countryName": "Sao Tome and Principe",
        "countryCode": "ST",
        "id": 209
    },
    {
        "countryName": "El Salvador",
        "countryCode": "SV",
        "id": 210
    },
    {
        "countryName": "Sint Maarten (Dutch part)",
        "countryCode": "SX",
        "id": 211
    },
    {
        "countryName": "Syrian Arab Republic",
        "countryCode": "SY",
        "id": 212
    },
    {
        "countryName": "Eswatini",
        "countryCode": "SZ",
        "id": 213
    },
    {
        "countryName": "Turks and Caicos Islands",
        "countryCode": "TC",
        "id": 214
    },
    {
        "countryName": "Chad",
        "countryCode": "TD",
        "id": 215
    },
    {
        "countryName": "French Southern Territories",
        "countryCode": "TF",
        "id": 216
    },
    {
        "countryName": "Togo",
        "countryCode": "TG",
        "id": 217
    },
    {
        "countryName": "Thailand",
        "countryCode": "TH",
        "id": 218
    },
    {
        "countryName": "Tajikistan",
        "countryCode": "TJ",
        "id": 219
    },
    {
        "countryName": "Tokelau",
        "countryCode": "TK",
        "id": 220
    },
    {
        "countryName": "Timor-Leste",
        "countryCode": "TL",
        "id": 221
    },
    {
        "countryName": "Turkmenistan",
        "countryCode": "TM",
        "id": 222
    },
    {
        "countryName": "Tunisia",
        "countryCode": "TN",
        "id": 223
    },
    {
        "countryName": "Tonga",
        "countryCode": "TO",
        "id": 224
    },
    {
        "countryName": "Turkey",
        "countryCode": "TR",
        "id": 225
    },
    {
        "countryName": "Trinidad and Tobago",
        "countryCode": "TT",
        "id": 226
    },
    {
        "countryName": "Tuvalu",
        "countryCode": "TV",
        "id": 227
    },
    {
        "countryName": "Taiwan, Province of China",
        "countryCode": "TW",
        "id": 228
    },
    {
        "countryName": "Tanzania, United Republic of",
        "countryCode": "TZ",
        "id": 229
    },
    {
        "countryName": "Uganda",
        "countryCode": "UG",
        "id": 231
    },
    {
        "countryName": "United States Minor Outlying Islands",
        "countryCode": "UM",
        "id": 232
    },
    {
        "countryName": "United States of America",
        "countryCode": "US",
        "id": 233
    },
    {
        "countryName": "Uruguay",
        "countryCode": "UY",
        "id": 234
    },
    {
        "countryName": "Uzbekistan",
        "countryCode": "TUZK",
        "id": 235
    },
    {
        "countryName": "Holy See",
        "countryCode": "VA",
        "id": 236
    },
    {
        "countryName": "Saint Vincent and the Grenadines",
        "countryCode": "VC",
        "id": 237
    },
    {
        "countryName": "Venezuela (Bolivarian Republic of)",
        "countryCode": "VE",
        "id": 238
    },
    {
        "countryName": "Virgin Islands (British)",
        "countryCode": "VG",
        "id": 239
    },
    {
        "countryName": "Virgin Islands (U.S.)",
        "countryCode": "VI",
        "id": 240
    },
    {
        "countryName": "Viet Nam",
        "countryCode": "VN",
        "id": 241
    },
    {
        "countryName": "Vanuatu",
        "countryCode": "VU",
        "id": 242
    },
    {
        "countryName": "Wallis and Futuna",
        "countryCode": "WF",
        "id": 243
    },
    {
        "countryName": "Samoa",
        "countryCode": "WS",
        "id": 244
    },
    {
        "countryName": "Yemen",
        "countryCode": "YE",
        "id": 245
    },
    {
        "countryName": "Mayotte",
        "countryCode": "YT",
        "id": 246
    },
    {
        "countryName": "South Africa",
        "countryCode": "ZA",
        "id": 247
    },
    {
        "countryName": "Zambia",
        "countryCode": "ZM",
        "id": 248
    },
    {
        "countryName": "Tokelau",
        "countryCode": "Zimbabwe",
        "id": 249
    }
]

export const DOMAIN_LIST = [
  {
    id: 1,
    key: "",
    value: "-- Select Domaint --",
  },
//   {
//     id: 2,
//     key: "bajajfinserv.in",
//     value: "bajajfinserv.in",
//   },
//   {
//     id: 3,
//     key: "irisbusiness.com",
//     value: "irisbusiness.com",
//   },
  {
    id: 4,
    key: "idfcfirstbank.com",
    value: "idfcfirstbank.com",
  },
];

export interface LoaderState {
    show: boolean;
}

export interface DomainList {
  id: number;
  key: string;
  value: string;
}
