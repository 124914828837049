import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { GetterSetterService } from '../services/getter-setter.service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private GetSet: GetterSetterService,
    private loaderService: LoaderService
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    const { url } = req;

    if (url.indexOf("/login/sso") > -1) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });

      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
          },
          (err: any) => {
            this.onEnd();
          }
        )
      );
    }

    if (url.indexOf("/public/token/") > -1) {

      req = req.clone({
        headers: req.headers.set("tenant", "asp"),
      });

      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
          },
          (err: any) => {
            this.onEnd();
          }
        )
      );
    }

    if (url.indexOf('/login') > -1 || url.indexOf('/registration') > -1 || url.indexOf('/checktokenvalidity') > -1 || url.indexOf('/verifytotp') > -1 ||
      url.indexOf('/forgotpassword') > -1 || url.indexOf('/completeRegistration') > -1 || url.indexOf('/resetpassword') > -1 || url.indexOf('/confirmregistration') > -1
      || url.indexOf('/user/changepassword') > -1 || (url.indexOf('/subscription') > -1 && url.indexOf('/active') < 0)) {

      // req = req.clone({
      //     headers: req.headers.set('tenant', 'dev')
      // });
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      },
        (err: any) => {
          this.onEnd();
        }));
    }

    req = req.clone({
      headers: req.headers.set('x-auth-token', this.tokenService.getter())
    });

    if (this.GetSet.getSetCompanyId()) {
      req = req.clone({
        headers: req.headers.set('companyId', this.GetSet.getSetCompanyId())
      });
    }

    if (url.indexOf('/upload/invoices') > -1 || url.indexOf('/einvoice/logo/add') > -1) {
      // req = req.clone({
      //     headers: req.headers.set('Content-Type', 'multipart/form-data')
      // });
    } else {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.onEnd();
      }
    },
      (err: any) => {
        this.onEnd();
      }));
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
