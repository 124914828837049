import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  header: any;

  constructor(
    private http: HttpClient
  ) {
    this.header = new HttpHeaders();
    this.header = this.header.set('clientId', 'IRISdmsh2eeheg956gfhryslnbtsv8902bdb');
    this.header = this.header.set('clientSecret', '8ne46hfhhfk53nfjkh59fazmlopqh202');
  }

  getSubscriptionPlan(data:any) {
    return this.http.get(`${environment.apiUrl}/mgmt/subscription/active?pan=${data.pan}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getCorporatePlan(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/subscription/subscriptionPlan?`, {
      params: {
        subscriptionCode: (data.subCode != undefined ? data.subCode : data)
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }

  addBusinessFunction(data: any) {
    return this.http.put(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  viewEntity(comId: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/business?`, {
      params: {
        companyid: comId
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  updateBusiness(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((response) => {
      return response;
    }));
  }
  subscribeData(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/subscription`, data , { headers: this.header }).pipe(map((response) => {
      return response;
    }));
  }
  getGstinData(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/ctinDetail/fetchCtinDetail?`, {
      params: {
        ctin: data
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  addGstinFunction(data: any) {
    return this.http.put(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  updateNIC(data) {
    // return this.http.put(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((resp) => {
    //   return resp;
    // }));
  }

  fetchImages() {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/logo/viewall`).pipe(map((resp) => {
      return resp;
    }));
  }

  newtemplate() {
    return this.http.put(`${environment.apiUrl}/onyx/einvoice/template/add`, {}).pipe(map((resp) => {
      return resp;
    }));
  }

  saveTemplate(data) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/save`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getTemplateById(id) {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/template/view?id=${id}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getAllTemplates() {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/template/viewall`).pipe(map((resp) => {
      return resp;
    }));
  }

  changeTemplateStatus(data) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/update`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  previewPDF(html) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/preview`, html, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  uploadAsset(name, fD) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('img', fD);
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/logo/add`, formData).pipe(map((resp) => {
      return resp;
    }));
  }
}
