<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                    <div class="auth-form-transparent text-left p-3">
                        <div class="brand-logo">
                            <img src="assets/images/IRISGST-logo.png" alt="logo">
                        </div>
                        <form [formGroup]="passwordForm" class="pt-3">
                            <h3 class="clrWhite">Forgot Password</h3>
                            <div class="form-group">
                                <label for="exampleInputEmail">Email</label>
                                <input type="text" class="form-control" formControlName="email" id="exampleInputEmail" placeholder="Email">
                            </div>
                            <div *ngIf="isSubmitted ">
                                <span class="text-danger" *ngIf="!passwordForm.get('email').dirty && passwordForm.get('email').errors.required">Email Required</span>
                                <span class="text-danger" *ngIf="passwordForm.get('email').dirty && passwordForm.get('email').errors">Invalid Email</span>
                            </div>
                            <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="resetPassword()">
                                    Reset Password
                                </button>
                            </div>
                            <div class="my-2 d-flex justify-content-between align-items-center">
                                <a (click)="signIn()" class="clrWhite"> Sign In</a>
                            </div>
                        </form>
                    </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
