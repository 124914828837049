import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginComponent } from './auth/components/login/login.component';
import { PreventGuard } from './auth/guards/prevent.guard';
import { ForgotPasswordComponent } from './auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SignUpComponent } from './auth/components/sign-up/sign-up.component';
import { ThankYouComponent } from './auth/components/thank-you/thank-you.component';
import { CompleteRegistrationComponent } from './auth/components/complete-registration/complete-registration.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ChangePasswordComponent } from './auth/components/change-password/change-password.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SessionClearComponent } from './auth/components/session-clear/session-clear.component';
import { UpdatePasswordComponent } from './auth/components/update-password/update-password.component';
import { SubscriptionComponent } from './base/components/subscription/subscription.component';
import { VerifyOtpComponent } from './auth/components/verify-otp/verify-otp.component';
import { DomainSelectionComponent } from './domain-selection/domain-selection.component';


const routes: Routes = [
  {
    path: 'forgot-password',
    canActivate: [PreventGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'login',
    canActivate: [PreventGuard],
    component: LoginComponent
  },{
    path: 'verify-otp',
    canActivate: [PreventGuard],
    component: VerifyOtpComponent
  },{
    path: 'domain-selection',
    canActivate: [PreventGuard],
    component: DomainSelectionComponent
  },{
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
   {
    path: 'reset-password',
    canActivate: [PreventGuard],
    component: ResetPasswordComponent
  }, {
    path: 'signup',
    canActivate: [PreventGuard],
    component: SignUpComponent
  }, {
    path: 'thankyou',
    canActivate: [PreventGuard],
    component: ThankYouComponent
  }, {
    path: 'complete-reg',
    canActivate: [PreventGuard],
    component: CompleteRegistrationComponent
  },
  {
    path: 'change-password',
    canActivate: [PreventGuard],
    component: ChangePasswordComponent
  },
  {
    path: 'add-business',
    canActivate: [AuthGuard],
    component: AddBusinessComponent
  },
  {
    path: 'update-profile',
    canActivate: [AuthGuard],
    component: UpdatePasswordComponent
  },
  {
    path: 'subscription',
    // canActivate: [PreventGuard],
    component: SubscriptionComponent
  },
  {
    path: 'logout-all-session',
    canActivate: [PreventGuard],
    component: SessionClearComponent
  },{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
