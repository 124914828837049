import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { BaseResponse } from '../../../models/response';
import { ToasterService } from '../../../shared/services/toaster.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isSubmitted: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService
  ) { }
  passwordForm: FormGroup;
  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.passwordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  resetPassword() {
    this.isSubmitted = true;
    if (this.passwordForm.valid) {
      this.authService.resetPassword(this.passwordForm.value).subscribe((response:BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess(response.message);
          this.router.navigate(['login']);
        } else {
          this.toaster.showError(response.message);
        }
      })
    }
  }
  signIn() {
    this.router.navigate(['login']);
  }

}
