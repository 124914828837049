<section class="container py-4">
    <div class="row">
        <div class="col-md-12">
            <ul id="tabsJustified" class="nav nav-tabs iris-gst-display-flex">
                <li class="nav-item">
                    <a href="" data-target="#addBusiness" data-toggle="tab" class="nav-link "data-toggle="tab" class="nav-link "
                    [ngClass]="activeStep1 ? 'active': ''" (click)="OpenForm('step1')"
                       >Business</a></li>
                <!-- <li class="nav-item"><a href="" data-target="#subscribe" data-toggle="tab" class="nav-link "
                [ngClass]="activeStep2 ? 'active': ''" (click)="OpenForm('step2')">Subscription</a></li> -->
               
            </ul>
            <br>
            <div id="tabsJustifiedContent" class="tab-content card">
                <div id="addBusiness" class="tab-pane fade " [ngClass]="activeStep1 ? 'active show': ''"
                *ngIf="activeStep1">
                <div class="row pb-2">
                    
                    <form id="example-form" class="col-md-12" [formGroup]="addBusinessForm">                            
                        <div class="form-group">
                            <label for="companyname">Business Name <sup>*</sup></label>
                            <input type="text" class="form-control col-md-8" formControlName="companyname"
                                placeholder="Enter Business Name" [ngClass]="{ 'has-danger': isSubmitted && addBusinessForm.get('companyname').errors }">
                            <div *ngIf="isSubmitted">
                                <span class="text-danger"
                                    *ngIf="!addBusinessForm.get('companyname').dirty && addBusinessForm.get('companyname').errors.required">Please
                                    enter Business Name
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="pan">PAN <sup>*</sup> <span class="" data-toggle="tooltip"
                                    data-placement="right" title=""
                                    data-original-title="This will be the root PAN for your business. If your organisation has multiple PAN, you an use a dummy PAN here to group all your entities together."><i
                                        class="mdi mdi-alert-octagon fs20 clrOrange"></i></span></label>
                            <input type="text" class="form-control col-md-8" placeholder="Enter PAN"
                                formControlName="pan">
                            <div *ngIf="isSubmitted">
                                <span class="text-danger"
                                    *ngIf="!addBusinessForm.get('pan').dirty && addBusinessForm.get('pan').errors.required">Please
                                    enter PAN Number
                                </span>
                                <span class="text-danger"
                                    *ngIf="addBusinessForm.get('pan').dirty && addBusinessForm.get('pan').errors">Please
                                    enter valid PAN Number
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Address</label>
                            <textarea class="form-control col-md-8" id="exampleTextarea1" rows="4"
                                placeholder="Enter Address" formControlName="address"></textarea>
                        </div>
                    </form>
                </div>
                <button type="submit" class="btn btn-primary" (click)="checkSubmitValidation('addBusinessTab')" [disabled]="isDisabled">Submit</button>
                </div>
                <!-- <div id="subscribe" class="tab-pane fade active show" *ngIf="activeStep2">
                    <div class="row pb-2">
                        <form class="col-md-12" [formGroup]="subscribeForm">
                            <div class="form-group">
                                <label>Subscription code <sup>*</sup> <span class="" data-toggle="tooltip"
                                        data-placement="right" title=""
                                        data-original-title="Contact support@irisgst.com to get subscription code"><i
                                            class="mdi mdi-alert-octagon fs20 clrOrange"></i></span></label>
                                <input type="text" class="form-control col-md-8" placeholder="Enter Code"
                                    formControlName="subCode">
                                <div *ngIf="isSubmitted">
                                    <span class="text-danger"
                                        *ngIf="!subscribeForm.get('subCode').dirty && subscribeForm.get('subCode').errors.required">Please
                                        enter Subscription Code
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <button type="submit" class="btn btn-primary"
                    (click)="checkSubmitValidation('subscribeTab')">Finish</button>
                </div> -->
               
                
               
            </div>
        </div>
    </div>
</section>